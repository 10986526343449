document.addEventListener('DOMContentLoaded', function() {
    // fix compare button visibility
    var topbutton = document.getElementById("top_button");
    window.onscroll = function() {scrollFunction(topbutton)};
    function scrollFunction(topbutton) {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        topbutton.style.display = "block";
      } else {
        topbutton.style.display = "none";
      }
    }

    window.addEventListener('scroll', function() {
        if (window.scrollY > 130) {
            const compareFix = document.querySelector('.btn--compare.is-fix');
            if (compareFix) {
                if (compareFix.classList.contains('is-hidden')) {
                    compareFix.classList.remove('is-hidden');
                }
            }
        }else {
            const compareFix = document.querySelector('.btn--compare.is-fix');
            if (compareFix) {
                if (!compareFix.classList.contains('is-hidden')) {
                    compareFix.classList.add('is-hidden');
                }
            }
        }
    });

    var mainSlideshow = document.getElementById('slideshow');
    if (mainSlideshow) {
        var mainFlkty = new Flickity(mainSlideshow, {
            accessibility: true,
            adaptiveHeight: true,
            autoPlay: 6000,
            pauseAutoPlayOnHover: false,
            cellAlign: 'center',
            cellSelector: undefined,
            contain: true,
            draggable: true,
            dragThreshold: 3,
            freeScroll: false,
            friction: 0.3,
            groupCells: false,
            initialIndex: 0,
            lazyLoad: 1,
            percentPosition: true,
            prevNextButtons: true,
            pageDots: true,
            resize: true,
            rightToLeft: false,
            setGallerySize: true,
            watchCSS: false,
            wrapAround: true,
            // arrowShape: {
            //     x0: 10,
            //     x1: 60, y1: 50,
            //     x2: 65, y2: 45,
            //     x3: 20
            // }
            arrowShape: {
                x0: 10,
                x1: 60,
                y1: 50,
                x2: 60,
                y2: 45,
                x3: 15
            }
        });
    }

    var saleCarousel = document.getElementById('sale-carousel');
    if (saleCarousel) {
        var saleFlkty = new Flickity(saleCarousel, {
            accessibility: true,
            // adaptiveHeight: true,
            autoPlay: 6000,
            // pauseAutoPlayOnHover: false,
            cellAlign: 'left',
            // cellSelector: undefined,
            contain: true,
            // draggable: true,
            // dragThreshold: 3,
            // freeScroll: false,
            // friction: 0.3,
            groupCells: false,
            // initialIndex: 0,
            // lazyLoad: true,
            percentPosition: false,
            prevNextButtons: false,
            pageDots: false,
            // resize: true,
            // rightToLeft: false,
            // setGallerySize: true,
            // watchCSS: false,
            wrapAround: true
        });

        // previous
        var previousButton = document.querySelector('.carousel-btn--previous');
        previousButton.addEventListener('click', function() {
            saleFlkty.previous();
        });

        // next
        var nextButton = document.querySelector('.carousel-btn--next');
        nextButton.addEventListener('click', function() {
            saleFlkty.next();
        });
    }

    var brandCarousel = document.getElementById('brand-carousel');
    if (brandCarousel) {
        var brandFlkty = new Flickity(brandCarousel, {
            accessibility: true,
            // adaptiveHeight: true,
            autoPlay: 6000,
            // pauseAutoPlayOnHover: false,
            cellAlign: 'left',
            // cellSelector: undefined,
            contain: true,
            draggable: true,
            dragThreshold: 3,
            freeScroll: true,
            friction: 0.3,
            groupCells: false,
            initialIndex: 0,
            lazyLoad: false,
            imagesLoaded: true,
            percentPosition: false,
            prevNextButtons: false,
            pageDots: false,
            resize: true,
            wrapAround: true
            // rightToLeft: false,
            // setGallerySize: true,
            // watchCSS: false,
        });

        // previous
        var previousButton = document.querySelector('.brand-btn--previous');
        previousButton.addEventListener('click', function() {
            brandFlkty.previous();
        });

        // next
        var nextButton = document.querySelector('.brand-btn--next');
        nextButton.addEventListener('click', function() {
            brandFlkty.next();
        });
    }

    baguetteBox.run('.gallery');

    var scrollTop = document.querySelector('.scroll-top');
    if (scrollTop) {
        scrollTop.addEventListener('click', function() {
            scrollTo(document.body, 0, 500);
        });
    }

    function scrollTo(element, to, duration) {
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        var animateScroll = function() {
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    //t = current time
    //b = start value
    //c = change in value
    //d = duration
    Math.easeInOutQuad = function(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    var menu_triggers = document.querySelectorAll('[data-menu-trigger]');
    // if (menu_triggers.length) {
    //     for (var i = 0; i < menu_triggers.length; i++) {
    //
    //         var menu_trigger = menu_triggers[i];
    //         menu_trigger.addEventListener('click', function(e) {
    //             e.preventDefault();
    //
    //             var menu_type = this.dataset.menuTrigger;
    //             var menu_item = document.getElementById('menu-' + menu_type);
    //
    //             var all_menu_items = document.querySelectorAll('.menu-container__dropdown');
    //
    //             forEach(all_menu_items, function (index, elem) {
    //                 console.log(elem);
    //                 removeClass(elem, 'menu-container__trigger--open');
    //                 // elem.classList.remove('menu-container__trigger--open');
    //             });
    //
    //             if (menu_item) {
    //                 toggleClass(menu_item, 'menu-container__dropdown--open');
    //                 toggleClass(this, 'menu-container__trigger--open');
    //             }
    //         });
    //
    //     }
    // }

    if (menu_triggers.length) {
        menu_triggers.forEach(function(menu_trigger) {
            menu_trigger.addEventListener('click', function(e) {
                e.preventDefault();

                var all_dropdowns = document.querySelectorAll(
                    '.menu-container__dropdown'
                );

                var trigger = e.currentTarget;
                var trigger_selector = trigger.dataset.menuTrigger;
                var dropdown = document.getElementById(
                    'menu-' + trigger_selector
                );

                onetime(document, 'click', close);

                function close(e) {
                    if (!trigger.contains(e.target) && e.target !== trigger) {
                        trigger.classList.remove(
                            'menu-container__trigger--open'
                        );
                        dropdown.classList.remove(
                            'menu-container__dropdown--open'
                        );
                    }
                }

                all_dropdowns.forEach(function(elem) {
                    if (elem !== dropdown) {
                        elem.classList.remove('menu-container__dropdown--open');
                    }
                });
                menu_triggers.forEach(function(elem) {
                    if (elem !== trigger) {
                        elem.classList.remove('menu-container__trigger--open');
                    }
                });

                trigger.classList.toggle('menu-container__trigger--open');
                dropdown.classList.toggle('menu-container__dropdown--open');
            });
        });
    }

    var search_triggers = document.querySelectorAll('[data-search]');
    if (search_triggers.length) {
        for (var i = 0; i < search_triggers.length; i++) {
            var search_trigger = search_triggers[i];
            search_trigger.addEventListener('click', function(e) {
                e.preventDefault();

                var search_type = this.dataset.search;
                var searchbar = document.getElementById('searchbar');

                if (search_type === 'close') {
                    hideElem(searchbar);
                } else if (search_type === 'open') {
                    searchbar.style.display = 'flex';
                }
            });
        }
    }

    var main_menu_triggers = document.querySelectorAll('[data-menu]');
    if (main_menu_triggers.length) {
        for (var i = 0; i < main_menu_triggers.length; i++) {
            var main_menu_trigger = main_menu_triggers[i];
            main_menu_trigger.addEventListener('click', function() {
                var main_menu_type = this.dataset.menu;
                var main_menu_panel = document.querySelector(
                    '[data-menu="panel"]'
                );
                var main_menu_list = document.querySelector(
                    '[data-menu="list"]'
                );

                if (main_menu_type === 'open') {
                    showElem(main_menu_panel);
                    showElem(main_menu_list);
                } else if (main_menu_type === 'close') {
                    hideElem(main_menu_panel);
                    hideElem(main_menu_list);
                }
            });
        }
    }

    // login modal
    var login_btn = document.querySelector('.btn-login');
    if (login_btn) {
        login_btn.addEventListener('click', function() {
            var modal = document.querySelector('.modal-login');
            if (modal.style.display == 'block') {
                modal.style.display = 'none';
            } else {
                var modal_form = modal.querySelector('.modal');
                modal.style.display = 'block';
                if(modal_form){
                    modal_form.style.display = 'block';
                }
            }
        });
    }

    // forgot password modal
    var btn_pwd = document.querySelector('.btn--forgot-password');
    if (btn_pwd) {
        btn_pwd.addEventListener('click', function(event) {
            event = event || window.event;
            event.preventDefault();

            var modal_login = document.querySelector('.modal-login');
            modal_login.style.display = 'none';

            var modal_pwd = document.querySelector('.modal-forgot-pass');
            modal_pwd.style.display = 'block';
        });
    }

    // modal - close
    var modal_close_btn = document.querySelectorAll('[data-modal-close]');

    if (modal_close_btn) {
        for (var i = 0; i < modal_close_btn.length; i++) {
            var close_btn = modal_close_btn[i];

            close_btn.addEventListener('click', function(event) {
                event = event || window.event;
                event.preventDefault();

                var modals = document.getElementsByClassName('modal-container');

                for (var i = 0; i < modals.length; i++) {
                    modals[i].style.display = 'none';
                }

                var simpleModals = document.getElementsByClassName('modal');

                for (var i = 0; i < simpleModals.length; i++) {
                    simpleModals[i].style.display = 'none';
                }
            });
        }
    }

    // profile delivery data toggle
    var delivery_toggle = document.querySelector(
        'input[name="same_billing_delivery"]'
    );

    if (delivery_toggle) {
        if (delivery_toggle.attributes['checked'].value) {
            document.querySelector('.delivery_data').style.display = 'none';
        } else {
            delivery_toggle.removeAttribute('checked');
        }
    }

    // compare modal show
    let butCompares = document.querySelectorAll('.btn--compare');
    if (butCompares) {
        butCompares.forEach(button => {
            button.addEventListener('click', e => {
                e.preventDefault();
                let modal = document.querySelector('.modal-compares');
                if (modal) {
                    modal.style.display = 'flex';
                }
            });
        });
    }

    // compare set counter
    function setCompareCounter(count) {
        const counters = document.querySelectorAll('.compare-count');
        if (counters) {
            counters.forEach(item => {
                item.textContent = count;
            });
        }
    }
    function increaseCompareCounter() {
        const counters = document.querySelectorAll('.compare-count');
        if (counters) {
            counters.forEach(item => {
                item.textContent = parseInt(item.textContent) + 1;
            });
        }
    }
    function decreaseCompareCounter() {
        const counters = document.querySelectorAll('.compare-count');
        if (counters) {
            counters.forEach(item => {
                item.textContent = parseInt(item.textContent) - 1;
                if (parseInt(item.textContent) < 0) {
                    item.textContent = 0;
                }
            });
        }
    }

    // get compare list
    function processCompareList(data) {
        if (data.compare_list) {
            setCompareCounter(data.compare_list.length);

            const modal = document.querySelector('.modal-compares .modal-body');
            if (modal) {
                modal.innerHTML = '';
            }

            data.compare_list.forEach(item => {
                const product = document.createElement('DIV');
                product.classList.add('modal-product');

                const anchor = document.createElement('A');
                anchor.classList.add('modal-product-delete');
                anchor.dataset.slug = item.slug;
                anchor.addEventListener('click', e => {
                    e.preventDefault();
                    const target = e.currentTarget;

                    var xhttp = new XMLHttpRequest();
                    xhttp.onreadystatechange = function() {
                        if (this.readyState == 4 && this.status == 200) {
                        }
                    };
                    xhttp.open(
                        'GET',
                        window.location.origin +
                            '/osszehasonlitas-torles/' +
                            item.slug,
                        true
                    );
                    xhttp.send();

                    const listItem = document.querySelector(
                        '[data-slug="' + target.dataset.slug + '"]'
                    );
                    if (listItem) {
                        listItem.classList.remove('is-active');
                    }
                    target.parentNode.remove();

                    decreaseCompareCounter();

                    return false;
                });
                product.appendChild(anchor);

                const wrapper = document.createElement('DIV');
                wrapper.classList.add('img-wrapper');
                const img = document.createElement('IMG');
                img.setAttribute('alt', 'photo');
                img.setAttribute(
                    'src',
                    window.location.origin + '/' + item.image
                );
                wrapper.appendChild(img);
                product.appendChild(wrapper);

                const title = document.createElement('H4');
                title.textContent = item.title;
                product.appendChild(title);

                const modal = document.querySelector(
                    '.modal-compares .modal-body'
                );
                if (modal) {
                    modal.appendChild(product);
                }
            });
        }
    }

    function getCompareList() {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
                processCompareList(JSON.parse(this.responseText));
            }
        };
        xhttp.open('GET', window.location.origin + '/osszehasonlitas', true);
        xhttp.send();
    }
    getCompareList();

    // compare toggle ajax
    const compareToggles = document.querySelectorAll('.product-compare');
    if (compareToggles) {
        compareToggles.forEach(item => {
            item.addEventListener('click', e => {
                e.preventDefault();
                const target = e.currentTarget;
                const route = target.dataset.route;

                if (!target.classList.contains('is-active')) {
                    // 4 termeknel tobb ne kerulhessen az osszehasonlitasba
                    const counter = document.querySelector('.compare-count');
                    if (counter) {
                        if (parseInt(counter.textContent) >= 4) {
                            const tooltipFull = target.parentNode.querySelector('.compare-tooltip.is-full');
                            if (tooltipFull) {
                                tooltipFull.classList.remove('is-hidden');
                                setTimeout(function() {
                                    tooltipFull.classList.add('is-hidden');
                                }, 3000);
                                return false;
                            }
                        }
                    }
                }

                target.classList.toggle('is-active');

                if (target.classList.contains('is-active')) {
                    increaseCompareCounter();
                } else {
                    decreaseCompareCounter();
                }
                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function() {
                    if (this.readyState == 4 && this.status == 200) {
                        processCompareList(JSON.parse(this.responseText));
                    }
                };
                xhttp.open('GET', route, true);
                xhttp.send();

                // tooltip sikeresen hozzadta az osszehasonlitashoz
                if (target.classList.contains('is-active')) {
                    const tooltipSuccess = target.parentNode.querySelector('.compare-tooltip.is-success');
                    if (tooltipSuccess) {
                        tooltipSuccess.classList.remove('is-hidden');
                        setTimeout(function() {
                            tooltipSuccess.classList.add('is-hidden');
                        }, 3000);
                    }
                }

                return false;
            });
        });
    }

    // compare delete all
    const compareDeleteAll = document.querySelector('.compare-delete-all');
    if (compareDeleteAll) {
        compareDeleteAll.addEventListener('click', e => {
            e.preventDefault();

            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function() {
                if (this.readyState == 4 && this.status == 200) {
                }
            };
            xhttp.open(
                'GET',
                window.location.origin + '/osszehasonlitas-torles-mind',
                true
            );
            xhttp.send();

            const modal = document.querySelector('.modal-compares .modal-body');
            if (modal) {
                modal.innerHTML = '';
            }

            const listItems = document.querySelectorAll(
                '.product-compare.is-active'
            );
            if (listItems) {
                listItems.forEach(item => {
                    item.classList.remove('is-active');
                });
            }

            setCompareCounter(0);

            return false;
        });
    }

    // termek osszehasonlitas tablazat
    // elso (fix) oszlop magassag beallitasa ciganykodas
    let compareTable = document.querySelector('.table-compare');
    if (compareTable) {
        let rows = compareTable.querySelectorAll('tr');
        if (rows) {
            rows.forEach(row => {
                let colFirst = row.querySelector('td:first-child');
                if (colFirst) {
                    if (row.classList.contains('is-attribute')) {
                        colFirst.style.height =
                            'calc(' + row.offsetHeight + 'px - 2em)';
                    } else {
                        colFirst.style.height =
                            'calc((' + row.offsetHeight + 'px - 2em) - 1px)';
                    }
                }
            });
        }
    }
});

// profile delivery data toggle
function same_billing_delivery_changed(event) {
    document.querySelector('.delivery_data').style.display = event.target
        .checked
        ? 'none'
        : 'block';
}

let mobileForms = document.querySelectorAll(
    '.tab-nav--mobile > form > .form-select'
);

if (mobileForms) {
    for (let i = 0; i < mobileForms.length; i++) {
        mobileForms[i].addEventListener('change', e => {
            var select = e.currentTarget;
            var url = select.options[select.selectedIndex].value;
            url = url.replace(/^[ ]+|[ ]+$/g, '');
            if (url) {
                window.location.replace(url);
            }
        });
    }
}


function topFunction() {
  window.scrollTo({top: 0, behavior: 'smooth'});
}

function scroolToId(id) {
  document.getElementById(id).scrollIntoView({
  behavior: 'smooth'
  });
}
